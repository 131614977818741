import React from "react";
import style from "./Promotion.module.css"

const Promotion = () => {
    return (
        <>
          <div className={style.promotionBck}> </div>
              <div className="thirdContainer">
                <h1 className={style.promotionheading}>Limited Time Deal!</h1>
             <div className={style.promotionDis}>
                 <div className={style.promotionContent}>
                     <h3>First box FREE* on <b>6 and 12 month commitment</b> with code FREEBOX <br/>*Just pay shipping</h3>
                     <h2>Promotion Details:</h2>
                     <p>
                     This promotion is valid online only at www.myzoobox.com. Promo code FREEBOX
6 and 12 month subscriptions are committments to the full length of the term.  No refunds or early cancellations before the 6 or 12 month term has ended.  6 and 12 month plans rebill on the 14th/15th and ship the first week of the new month.  All plans auto-renew monthly after commitment term.  Cancel upcoming renewal at anytime.</p><p>Discount does not apply to automatic or user-initiated renewal of existing subscriptions. Discount cannot be combined with any other offers and/or applied retroactively to previously placed orders.</p><p>Offer excludes any upgrade options chosen at checkout.  Offer excludes taxes and shipping. While supplies last.</p>
<p>
New subscribers who have missed the cut-off date of the last day of the month, may email customer support at <a href="mailto:support@myzoobox.com">support@myzoobox.com </a>to inquire if the previous month's box is available for immediate shipment.</p>
<p>
If you have any questions please email us at <a href="mailto:support@myzoobox.com">support@myzoobox.com </a>
                     </p>
                 </div>
                 <div className={style.promotionBanner}>
                     <img src="/image/i.png" alt="#" />
                 </div>
             </div>
              </div>
        </>
    )
}

export default Promotion