import { Link, NavLink ,useLocation} from "react-router-dom";
import { animateScroll } from "react-scroll";
import style from "./footer.module.css";

const Footer = (props) => {
  const location = useLocation();
  return (
    <div className={style.footer}>
      <div className="container">
        <div className={style.footerDis}>
          <div className={style.footerLogo}>
         <Link to="/" onClick={() => animateScroll.scrollToTop()}>
         <img src={"/image/icon/l.png"} alt="logo" width={100} loading="lazy" />
         </Link>
          
          </div>
          <div className={`grid4 ${style.footerBoxes}`}>
            <div className={style.footerSection}>
              <h1>Learn More</h1>
      
              <Link to="/about-us" onClick={() => animateScroll.scrollToTop()}>About My ZOO Box</Link>
              {/* <Link  to="/contact-us"  onClick={() => animateScroll.scrollToTop()}>EMPOWER Foundation</Link> */}
              {/* <Link to="/faq" onClick={() => animateScroll.scrollToTop()}>One Tree Planted</Link> */}
               <Link to="/faq" onClick={() => animateScroll.scrollToTop()}>FAQs</Link>
               <Link to="/schools" onClick={() => animateScroll.scrollToTop()}>School Orders</Link>
               <Link to="/empower-foundation" onClick={() => animateScroll.scrollToTop()}>EMPOWER Foundation</Link>
               <Link
                to="/gifting"
                onClick={() => {
             
                  animateScroll.scrollToTop();
                }}
            
              >
                Gift Guide
              </Link>
              <Link to="/contact-us" onClick={() => animateScroll.scrollToTop()}>Contact Us</Link>
              <Link to="/partnership" onClick={() => animateScroll.scrollToTop()}>Affiliates & Partnerships at My ZOO Box</Link>
            
            </div>
           
            <div className={style.footerSection}>
              <h1>My ZOO Box Clubs</h1>
        
              <Link to="/cub-club" onClick={() => animateScroll.scrollToTop()} className={style.footerClubs}>
                <img src="/image/cc.png"/>
                <div>
                  <p>Cub Club</p>
                  <span>3 - 5 years old</span>
                </div>
              </Link>
              <Link  to="/zoologist-club"  onClick={() => animateScroll.scrollToTop()}  className={style.footerClubs}>
              <img src="/image/zz.png"/>
                <div>
                  <p>Zoologist Club</p>
                  <span>5 - 12 years old</span>
                </div>
              </Link>


           
            </div>

            <div className={style.footerSection}>
              <h1>The Zoo Store</h1>
              {/* <Link to="/demo" onClick={() => animateScroll.scrollToTop()}>Shop Past EDventures</Link> */}
              {/* <Link to="/the-zoo-store" onClick={() => animateScroll.scrollToTop()}>The ZOO Store 2.0 - Coming Soon</Link> */}
              <Link to="/zoo-store" onClick={() => animateScroll.scrollToTop()}>The ZOO Store</Link>
            </div>
            <div className={style.footerSection}>
              <h1>Social</h1>
              <div className={style.socials}>
                <a href="https://www.facebook.com/zooboxfun" target="_blank"><img src="/image/icon/fac.svg"/></a>
                 <a href="https://www.instagram.com/zooboxfun/" target="_blank"> <img src="/image/icon/ins.svg"/></a>
               
                {/* <img src="/image/icon/twit.svg"/> */}
               <a href="https://www.youtube.com/channel/UCZ9u_73Dv9VJwL4gjzyBZlA" target="_blank">  <img src="/image/icon/you.svg"/></a>
              </div>
      
            </div>
          </div>
        </div>
        <div className={style.privacyDis}>
        <p>© 2024 My ZOO Box | All Rights Reserved</p>
        <div>
          <Link to="/privacy-policy" onClick={() => animateScroll.scrollToTop()} >Privacy Policy</Link > | <Link to="/cookie-policy" onClick={() => animateScroll.scrollToTop()}>Cookie Policy</Link> | <Link to="/terms-of-service" onClick={() => animateScroll.scrollToTop()}>Terms and Conditions</Link>
        </div>
      </div>
      </div>
      
    </div>
  );
};

export default Footer;
